import React from "react"
import { Layout } from "../components/layout"
import Button from "../components/button"
import { Link } from "gatsby"
import styles from "../styles/style"

const Disclaimer = () => {
  return (
    <Layout>
      <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <section className="text-gray-700 body-font mt-6 mb-8">
            <div className="container mx-auto flex flex-col w-full">
              <h2 className="text-xl title-font  font-bold mb-6">
                <span className="text-green-gradient text-2xl">
                  This Disclaimer applies to all chilli extracts and hot sauces
                  in our range.
                </span>
              </h2>
              <p className="mb-4 leading-relaxed">
                I have been warned and fully understand that these products
                contain extreme heat and should be used and handled responsibly.
              </p>
              <p className="mb-4 leading-relaxed">
                These products are to be used entirely at my own risk and I
                understand the potential danger if used or handled
                irresponsibly.
              </p>
              <p className="mb-4 leading-relaxed">
                If I give any of these products as a gift, I will make the
                recipient aware of the potential danger if used or handled
                irresponsibly.
              </p>
              <p className="mb-4 leading-relaxed">
                I accept that H&S Peppers, our suppliers, and manufacturers
                will, under no circumstances, be responsible for, or liable for,
                any claims of injury or damage arising from the use or misuse of
                these products and by purchasing these products, whether for
                myself or as a gift. I acknowledge and agree to this fact
                without question.
              </p>
              <p className="mb-4 leading-relaxed">
                I am not inebriated or of unsound mind and am fully able to make
                a rational decision to purchase these products.
              </p>
            </div>
            <div className="flex justify-center ">
              <Link to="/products" alt="h&s pepper products">
                <Button label="View Products" styles={`mt-2`} />
              </Link>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Disclaimer
